@font-face {
  font-family: 'AudiowideRegular';
  src: local('AudiowideRegular'), url(./fonts/AudiowideRegular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: url('../public/licenses.jpeg');
  min-height: 100vh;
  padding: 1rem;
}

.console {
  background-color: #333366;
  color: #FFFFFF;
  max-width: 400px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item {
  display: flex;
}

.mat {
  padding: 1rem;
}

.mb2 {
  margin-top: 0;
  margin-bottom: 2rem;
  margin-right: 0;
  margin-left: 0;
}
